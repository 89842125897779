import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { addDemo } from '../../api/demoapi';
import { formatPhoneNumber } from '../../helpers/formatterHelper';
import { ValidateEmail, validatePhone } from '../../helpers/validationHelper';
import CloseBtn from '../../assets/icons/close.svg'
import RoundedInput from '../../components/common/Rounded-Input';
import { sendGTMEvent, gtmRequestDemo } from '../../helpers/gtmHelper';

export default function RequestDemo({ onClose }) {
    const [customerInfo, setCustomerInfo] = useState({ name: '', email: '', phone: '', company: '', zipcode: '' });
    const [validation, setValidation] = useState({ name: true, email: true, phone: true, company: true, zipcode: true });
    const [submitting, setSubmitting] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [error, setError] = useState(null);

    const handleSave = (e) => {
        e.preventDefault();
        setSubmitting(true);
        var validated = true;
        var valid = validation;
        if (customerInfo.name == '') {
            valid = { ...valid, name: false };
            validated = false;
        } else {
            valid = { ...valid, name: true };
        }
        if (customerInfo.company == '') {
            valid = { ...valid, company: false };
            validated = false;
        } else {
            valid = { ...valid, company: true };
        }
        if (!ValidateEmail(customerInfo.email)) {
            valid = { ...valid, email: false };
            validated = false;
        } else {
            valid = { ...valid, email: true };
        }
        var phone = formatPhoneNumber(customerInfo.phone).replace('+', '');
        if (!validatePhone(phone)) {
            valid = { ...valid, phone: false };
            validated = false;
        } else {
            valid = { ...valid, phone: true };
        }
        if (customerInfo.zipcode == '') {
            valid = { ...valid, zipcode: false };
            validated = false;
        } else {
            valid = { ...valid, zipcode: true };
        }
        setValidation(valid);
        if (validated == false) {
            setSubmitting(false);
            return;
        }
        var info = {
            Name: customerInfo.name,
            Company: customerInfo.company,
            Email: customerInfo.email,
            Phone: phone,
            Zipcode: customerInfo.zipcode
        };
        addDemo(info).then(response => {
            if (response.data.success == false) {
                setSubmitting(false);
                setError(response.data.data);
            } else {
                setShowForm(false);
                sendGTMEvent(gtmRequestDemo(info))
                    
            }
        });
    }

    return (
        <section className="main-page-wrapper request-demo">
            <div className="modal-wrapper">
                <div onClick={onClose} className="sales-popup-close"></div>
                <div className="modal-body">
                    <div className="close-btn flex-center" onClick={onClose}>
                        <img src={CloseBtn} alt="close" />
                    </div>
                    <div className="modal-content">
                        <h1 className="modal-title">Contact</h1>
                        {showForm ?
                            <>
                            <p className="modal-discription">
                                Want to see how Facility Standard can help your organization? 
                                Fill out the form below to schedule a demo with our sales team. 
                                For urgent inquiries or immediate assistance, please do not hesitate 
                                to contact us directly at &nbsp; 
                                <a href="tel:816-768-6179" className="support-number">816-768-6179</a>.
                                </p>
                                {error != null &&
                                    <div className="internal-error">
                                        {error}
                                        <span className="close-button" onClick={setError(null)}>
                                            <span className="material-symbols-rounded">close</span>
                                        </span>
                                    </div>
                                }
                            <form onSubmit={handleSave} className="modal-form" id="demoform">
                                <RoundedInput
                                    label="Name"
                                    placeholder=""
                                    isRequired={true}
                                    value={customerInfo.name}
                                    validated={validation.name}
                                    onChange={(value) => {
                                        setCustomerInfo({ ...customerInfo, name: value });
                                        setValidation({ ...validation, name: value != '' });
                                    }}
                                    requiredText="Your name is required"
                                />
                                <RoundedInput
                                    id="customer-company"
                                    label="Organization"
                                    placeholder=""
                                    isRequired={true}
                                    value={customerInfo.company}
                                    validated={validation.company}
                                    onChange={(value) => {
                                        setCustomerInfo({ ...customerInfo, company: value });
                                        setValidation({ ...validation, company: value != '' });
                                    }}
                                    requiredText="Your organization is required"
                                />
                                <RoundedInput
                                    id="customer-email"
                                    label="Email Address"
                                    placeholder=""
                                    isRequired={true}
                                    value={customerInfo.email}
                                    validated={validation.email}
                                    onChange={(value) => {
                                        setCustomerInfo({ ...customerInfo, email: value });
                                    }}
                                    requiredText="A valid email address is required"
                                />
                                <div className="d-flex justify-between phone-code">
                                    <RoundedInput
                                        id="customer-phone"
                                        label="Phone"
                                        placeholder=""
                                        isRequired={true}
                                        value={customerInfo.phone}
                                        validated={validation.phone}
                                        onChange={(value) => {
                                            setCustomerInfo({ ...customerInfo, phone: value });
                                        }}
                                        requiredText="A valid phone number is required"
                                    />
                                    <RoundedInput
                                        id="customer-zipcode"
                                        label="Zipcode"
                                        placeholder=""
                                        isRequired={true}
                                        value={customerInfo.zipcode}
                                        validated={validation.zipcode}
                                        onChange={(value) => {
                                            setCustomerInfo({ ...customerInfo, zipcode: value });
                                            setValidation({ ...validation, zipcode: value != '' });
                                        }}
                                        requiredText="A valid zipcode is required"
                                    />
                                </div>
                               
                                <div className="form-btn">
                                    <button type="submit"
                                            className={`solid-primary-btn ${submitting ? 'submitReqBtn' : " "} `}>
                                            {submitting ? "Submitting request..." : "Submit"}
                                    </button>
                                </div>
                            </form>
                            </>
                            :
                            <div className="thank-you">
                                Thank you for your submission, our team will be in touch shortly.
                                If you need immediate assistance, call our customer support line at&nbsp;
                                <a href="tel:913-633-9992" className="support-number">913-633-9992</a>.
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

RequestDemo.propTypes = {
    onClose: PropTypes.func,
}
