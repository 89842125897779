import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import './productdetails.css';
//apis
import { getImpersonationCheck } from '../../api/cartapi.js';
import { getProductByDistrict, getProduct, getIdBySlug } from '../../api/productapi';
//helpers
import { updateTitle } from '../../helpers/titleHelper';
import { formatUSD } from '../../helpers/formatterHelper';
import { checkForDistrictId } from '../../helpers/checkForDistrictId';
//reducers
import { loadAllItems } from '../../store/myproject-reducer';
import { addNewItem } from '../../store/myproject-reducer';
import { addNewWishlistItem } from '../../store/mywishlist-reducer';
//components
import Banner from '../../components/common/ShowBannerDetails';
import Breadcrumbs from '../../components/common/BreadCrumb';
import { ProductOptions } from './productOptions';
import { AccordionItem } from '../../components/common/Accordion';
import { Quantity } from '../../components/common/Quantity';
import { ProductGallery } from '../../components/common/ProductGallery';
import { ProductSlider } from '../../components/common/Product-slick-slider';
import { gtmAddToCart, gtmAddToWishList } from '../../helpers/gtmHelper';

export default function ProductDetails() {
    const scrollRef = useRef(null);
    const productOptionRef = useRef(null);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const isPreview = location.pathname.includes('/preview');
    const { slug } = useParams();
    const [productId, setProductId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isAdding, setIsAdding] = useState(false);
    const [error, setError] = useState(null);
    const [product, setProduct] = useState({});
    const [productDetails, setProductDetails] = useState({});
    const [productOptions, setProductOptions] = useState({});
    const [productLine, setProductLine] = useState([]);
    const [updatedProduct, setUpdatedProduct] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [updatedPrice, setUpdatedPrice] = useState(0); // initialize with the base price
    const [isRequired, setIsRequired] = useState(null);
    const [wishlistButtonDisabled, setWishlistButtonDisabled] = useState(false);

    const myProjectItems = useSelector((state) => state.myProject.data.items);
    const queryParams = new URLSearchParams(location.search);
    const getCartId = parseInt(queryParams.get('cartid'));
    const typicalViewId = queryParams.get('typicalid');

    let istypicalLayoutProduct = localStorage.getItem('isTypicallayoutProduct');
    let gettypicalLayoutitems = localStorage.getItem('editTypicalLayout');
    let TypicalDetailList = "";
    if (gettypicalLayoutitems != undefined && gettypicalLayoutitems != null && gettypicalLayoutitems != '') {
        TypicalDetailList = JSON.parse(gettypicalLayoutitems);
    }

    useEffect(() => {
        if (slug != null && slug != '') {
            getIdBySlug(slug).then(response => {
                if (response.data.success == true) {
                    setProductId(response.data.data);
                } else {
                    if (parseInt(slug) != 'NaN') {
                        setProductId(parseInt(slug));
                    }
                }
            });
        }
    }, [slug])

    useEffect(() => {
        if (productId == null) { return; }
        let typicalProductDetails = JSON.parse(localStorage.getItem('editTypicalLayout')) || [];
        let districtID = typicalProductDetails && typicalProductDetails.districtId ? typicalProductDetails.districtId : localStorage.getItem('districtId');
        if (typicalViewId != undefined && typicalViewId != null && typicalViewId != '') {
            dispatch(loadAllItems("typicalProductDetailView"));
        }
        else if (TypicalDetailList != undefined && TypicalDetailList != null && TypicalDetailList != '') {
            districtID = TypicalDetailList.districtId;
        }

        if (isPreview) {
            // For preview, only use productId
            getProduct(productId)
                .then(handleResponse)
                .catch(handleError)
                .finally(handleFinally);
        } else {
            checkForDistrictId(navigate); //redirect to admin/districts if user is not logged into district
            // For non-preview, use both districtID and productId
            getProductByDistrict(districtID, productId)
                .then(handleResponse)
                .catch(handleError)
                .finally(handleFinally);
        }

    }, [productId, isPreview]);

    useEffect(() => {
        var setupProduct = { ...product };
        if (updatedProduct != null) { setupProduct = updatedProduct; }
        if (setupProduct && setupProduct.options) {
            setupProduct.options = setupProduct.options.map((option) => {
                if (option.items.length === 1 && !queryParams.get('cartid')) {
                    return {
                        ...option,
                        items: [option.items[0]],
                    };
                } else {

                    return {
                        ...option,
                        items: [],
                    };
                }

            });
            setUpdatedProduct(setupProduct);
        }
    }, [product]);

    useEffect(() => {
        const calculateTotalUpcharge = (options) => {
            return options.reduce((acc, option) => {
                const optionUpcharge = option.items.reduce(
                    (innerAcc, item) => innerAcc + (item.upCharge === undefined || item.upCharge === null ? 0 : item.upCharge),
                    0
                );
                return acc + optionUpcharge;
            }, 0);
        };

        const calculateDiscountedPrice = (price, discount) => {
            return price - (discount > 0 ? price * (discount / 100) : 0);
        };

        if (updatedProduct?.options?.length > 0) {
            const { options } = updatedProduct;
            const { price, discount } = productDetails;

            const totalUpCharge = calculateTotalUpcharge(options) + price;
            const totalPrice = calculateDiscountedPrice(totalUpCharge, discount);
            setUpdatedPrice(totalPrice);
        } else if (productDetails?.price) {
            const { price, discount } = productDetails;

            setUpdatedPrice(calculateDiscountedPrice(price, discount));
        }
        if (productDetails.discount !== undefined) {
            sessionStorage.setItem('productDiscount', productDetails.discount);
        } else {
            sessionStorage.setItem('productDiscount', 0);
        }
    }, [updatedProduct]);

    const handleResponse = (response) => {
        setProduct(response.data.data);
        setProductDetails(response.data.data.details);
        setProductOptions(response.data.data.options);
        setProductLine(response.data.data.productLine);

        updateTitle('Product', response.data.data.details.name);
    };

    const isSameOptionSelected = (items, option) => {
        return items.length > 0 && items[0].id === option.id;
    };

    const handleMultiSelection = (items, option) => {
        const itemIndex = items.findIndex((item) => item.id === option.id);
        if (itemIndex !== -1) {
            const newItems = [...items];
            newItems.splice(itemIndex, 1);
            return newItems;
        }
        return [...items, option];
    };

    const handleSingleSelection = (items, option) =>
        isSameOptionSelected(items, option) ? [] : [option];

    const updateOptionSelection = (currentOptions, option, type) => {
        const updatedOptions = currentOptions.options.map((optGroup) => {
            if (optGroup.type !== type ||
                option.productOptionId != optGroup.id) {
                return optGroup;
            }

            const updatedItems = (() => {
                switch (type) {
                    case 3:
                        return handleMultiSelection(optGroup.items, option);
                    case 2:
                        return optGroup.colorGroupId === option.color.colorGroupId
                            ? handleSingleSelection(optGroup.items, option)
                            : optGroup.items;
                    default:
                        return handleSingleSelection(optGroup.items, option);
                }
            })();
            return {
                ...optGroup,
                items: updatedItems,
            };
        });
        return {
            ...currentOptions,
            options: updatedOptions,
        };
    };

    const handleOptionSelection = (option, type) => {
        const currentOptions = { ...updatedProduct };
        const newProduct = updateOptionSelection(currentOptions, option, type);
        setUpdatedProduct(newProduct);
        if (isRequired != null) {
            const updaterequired = isRequired.filter(obj => obj.id !== option.productOptionId);
            setIsRequired(updaterequired);
        }
    };

    const handleError = (err) => {
        setError('Error fetching product: ' + err);
    };

    const handleFinally = () => {
        setIsLoading(false);
    };

    const handleAddToMyProject = () => {
        setIsAdding(true);
        const selectedOptionIds = updatedProduct.options.flatMap((optGroup) =>
            optGroup.items.map((item) => item.id)
      );

        const selectedOptions = updatedProduct.options?.flatMap(option =>
            option.items.map(optionItem => ({ productOptionItemId: optionItem.id, typicalLayoutItemId: 0 })));

        const requiredOption = productOptions.filter(obj => obj.isRequired);

        if (requiredOption.length > 0) {
            if (selectedOptionIds.length > 0) {
                const items = requiredOption.filter(obj => !obj.items.find(item => selectedOptionIds.includes(item.id)));
               

                if (items.length > 0) {
                    setIsRequired(items);
                    setIsAdding(false);
                    if (scrollRef.current) {
                        scrollRef.current.scrollIntoView({ behavior: 'smooth' });
                    }
                } else {
                    setIsRequired(null);
                    dispatch(
                        addNewItem({
                            productId: productDetails.productId,
                            quantity: quantity,
                            optionItemIds: selectedOptionIds,
                            optionItems: selectedOptions,
                            type: localStorage.typicallayout
                        })
                    ).then(() => {
                        gtmAddToCart(updatedProduct, productOptions, selectedOptionIds, quantity);
                        navigate('/my-project')
                    });
                }
            } else {
                setIsRequired(requiredOption);
                setIsAdding(false);
                if (scrollRef.current) {
                    scrollRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }
        } else {
            setIsRequired(null);
            dispatch(
                addNewItem({
                    productId: productDetails?.productId,
                    quantity: quantity,
                    optionItemIds: selectedOptionIds,
                    optionItems: selectedOptions,
                    description: productDetails?.description,
                    type: localStorage.typicallayout
                })
            ).then(() => {
              gtmAddToCart(updatedProduct, productOptions, selectedOptionIds, quantity);
                navigate('/my-project')
            });
        }
    };

    const handleAddToWishList = () => {
        setIsAdding(true);
        const selectedOptionIds = updatedProduct.options.flatMap((optGroup) =>
            optGroup.items.map((item) => item.id)
        );

        const requiredOption = productOptions.filter(obj => obj.isRequired);
        if (requiredOption.length > 0) {
            if (selectedOptionIds.length > 0) {
                const items = requiredOption.filter(obj => !obj.items.find(item => selectedOptionIds.includes(item.id)));
                if (items.length > 0) {
                    setIsRequired(items);
                    setIsAdding(false);
                    if (scrollRef.current) {
                        scrollRef.current.scrollIntoView({ behavior: 'smooth' });
                    }
                } else {
                    setWishlistButtonDisabled(true);
                    setIsRequired(null);
                    setIsAdding(false);
                  dispatch(
                    addNewWishlistItem({
                      productId: productDetails.productId,
                      quantity: quantity,
                      optionItemIds: selectedOptionIds
                    })

                  );
                  gtmAddToWishList(updatedProduct, productOptions, selectedOptionIds, quantity);

                }
            } else {
                setIsRequired(requiredOption);
                setIsAdding(false);
                if (scrollRef.current) {
                    scrollRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }
        } else {
            setWishlistButtonDisabled(true);
            setIsRequired(null);
            setIsAdding(false);
            dispatch(
                addNewWishlistItem({
                    productId: productDetails.productId,
                    quantity: quantity,
                    optionItemIds: selectedOptionIds
                })
          );
          gtmAddToWishList(productDetails, productOptions, selectedOptionIds, quantity);

        }
    };

    const handleQuantityChange = (event) => {
        const selectedQuantity = parseInt(event.target.value, 10);
        setQuantity(selectedQuantity);
    };

    useEffect(() => {
        let debounceTimer;
        clearTimeout(debounceTimer);
        const htmlContent = productOptionRef.current;

        const getProductOptions = myProjectItems.find((item) => item.cartId === getCartId);
        if (getProductOptions != null && getProductOptions != undefined) {
            getProductOptions.options.map((option) => {
                var selectedOption = product?.options?.find((x) => x.id === option.id);
                if (selectedOption != undefined && selectedOption != null) {
                    option.items.map((selectedItem) => {
                        var selectedDetail = selectedOption?.items.find((x) => x.id === selectedItem.id);
                        if (selectedDetail != undefined && selectedDetail != null) {
                            if (selectedOption.type == 0 || selectedOption.type == 1) {
                                debounceTimer = setTimeout(() => {
                                    if (!isLoading) {
                                        const itemWidth = htmlContent.querySelector('[data-action="productwidth"][id="' + selectedDetail?.id + '"]');
                                        itemWidth.click();
                                    }
                                }, 100);
                            }
                            else if (selectedOption.type == 2) {
                                debounceTimer = setTimeout(() => {
                                    if (!isLoading) {
                                        const itemColor = htmlContent.querySelector('[data-action="color"][id="' + selectedDetail?.colorId + '"]');
                                        itemColor.click();
                                    }
                                }, 100);
                            }
                            else if (selectedOption.type == 3 || selectedOption.type == 4) {
                                debounceTimer = setTimeout(() => {
                                    if (!isLoading) {
                                        const itemDimensions = htmlContent.querySelector('[data-action="dimensions"][id="' + selectedDetail?.id + '"]');
                                        itemDimensions.click();
                                    }
                                }, 100);
                            }
                        }
                    });
                }
            });
        }
    }, [isLoading, myProjectItems]);

    if (isLoading) {
        return <h1 className="loading-data">Loading...</h1>;
    }



    if (((typicalViewId != undefined && typicalViewId != null && typicalViewId != '') || (TypicalDetailList != undefined && TypicalDetailList != null && TypicalDetailList != '')
        || (istypicalLayoutProduct != undefined && istypicalLayoutProduct != null && istypicalLayoutProduct != '')) && error) {
        return (<div style={{"width":'100%', 'max-width': '800px', 'margin': 'auto'} }>
            <h2>
                This product is not part of your current organization&apos;s standard,
                but we can select a similar replacement.
            </h2>
            <p style={{'padding-top':'2rem'} }>
                Contact Facility Standard at <b>(816)-768-6179</b> for a similar product replacement recommendation.
            </p>
            <p style={{ 'padding-top': '2rem' }}><Link to="/contact">Contact Us</Link></p>
        </div>)
    }
    else if (error) {
        return <h1 className="loading-data">Product is not available</h1>;
    }

    if (!productDetails.name) {
        return <h1 className="loading-data">No Product found</h1>;
    }

    return (
        <>
            <Banner/>
            <div className="product-deatis-page-wrap">
                <div className="default-outer-wrapper product-details-default-wrap">
                    <div className="desktop-gallery">
                        <div className="default-center">
                            <div className="product-header-wrap">
                                <Breadcrumbs
                                    productName={productDetails.name}
                                    category={product.categories}
                                />
                            </div>
                        </div>


                    </div>
                    <div className="default-center">
                        <div className="product-header-wrap">
                            {/*<Breadcrumbs*/}
                            {/*	productName={productDetails.name}*/}
                            {/*	category={product.categories}*/}
                            {/*/>*/}
                            <div className="product-detail-inner">
                                <div className="product-detail-left">

                                    {product && product.media && product.media.length > 0 ? (
                                        <ProductGallery
                                            images={product.media.filter(item => !item?.mediaItem?.previewsrc?.includes("/static/media/"))} 
                                            alt={product.details.name} />
                                    ) : (
                                        //<span className={'product-item-image favorite'}>
                                        //	<span className="errorImage">Image Coming Soon</span>
                                        //</span>
                                        //<div className="product-gallery-item"><span className="product-item-image favorite"><span className="product-item-image"><span className="errorImage">Image Coming Soon</span></span></span><span className="product-item-content"><span className="product-title-amount-wrap"><span className="product-item-title">Student Desk</span><span className="product-item-amount">$1,755</span></span><span className="product-color-wrap"></span></span></div>
                                        <div className="product-info-gallery-wrap prod-bottom comming-soon-img-gallery">
                                            <div className="product-gallery-info-inner">
                                                <div className="product-gallery-thumb-row thamb-galler-comming-soon">
                                                    <div className="slick-slider slick-initialized">
                                                        <div className="slick-list">
                                                            <div className="slick-track slick-comming-soon-img">
                                                                <div data-index="0" className="slick-slide slick-active slick-current" aria-hidden="false">
                                                                    <div><div className="product-gallery-thumb-column">
                                                                    </div></div></div></div></div></div></div>
                                                <div className="product-gallery-slider-img-wrap">
                                                    <div className="slick-slider product-gallery-container slick-initialized">
                                                        <div className="slick-list">
                                                            <div className="slick-track">
                                                                <div data-index="0" className="slick-slide slick-active slick-current reponsive-width" aria-hidden="false">
                                                                    <div className="images-comming-soon-left">
                                                                        <div className="product-gallery-slide">
                                                                            <div className="product-gallery-image-wrap comming-soon-wrap">
                                                                                <span className="product-gallery-title-wrap"><span className="errorImage">Image Coming Soon</span></span>
                                                                            </div>
                                                                            <div className="product-gallery-caption-container">
                                                                                <p className="product-gallery-caption">Image may not depict the actual color selected</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    )}

                                    <div className="mobile-heading">
                                        <div className="default-center">
                                            <div className="product-header-wrap">
                                                <div className="product-detail-inner">
                                                    <div className="product-detail-right">
                                                        <p className="product-credit-desc">
                                                            {productLine &&
                                                                productLine.manufacturer &&
                                                                productLine.manufacturer.name}{' '}
                                                            #{productDetails.freedomCode}
                                                            <span className="product-detail-icon-wrap"></span>
                                                        </p>
                                                        <p className="product-detail-title">
                                                            {productDetails.name}
                                                        </p>
                                                        <p className="product-detail-price">
                                                            <span className="product-detail-price-amount">
                                                                {formatUSD(updatedPrice)}
                                                            </span>{' '}
                                                            {!isPreview ? "(District Contract Price)" : ""}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="product-detail-right" ref={scrollRef}>
                                    <div className="product-detail-desktop-heading">
                                        <p className="product-credit-desc">
                                            {productLine &&
                                                productLine.manufacturer &&
                                                productLine.manufacturer.name}{' '}
                                            #{productDetails.freedomCode}
                                            <span className="product-detail-icon-wrap"></span>
                                        </p>
                                        <h1 className="product-detail-title">{productDetails.name}</h1>
                                        <p className="product-detail-price">
                                            <span className="product-detail-price-amount">
                                                {formatUSD(updatedPrice)}
                                            </span>{' '}
                                            {!isPreview ? "(District Contract Price)" : ""}
                                        </p>
                                    </div>
                                    {/* <div className="mobile-gallery">*/}
                                    {/*	<ProductGallery />*/}
                                    {/*</div> */}

                                    <div
                                        className="product-detail-list"
                                        dangerouslySetInnerHTML={{
                                            __html: productDetails.shortDescription,
                                        }}
                                    ></div>
                                    {/*options section */}
                                    {productOptions && (
                                        <ProductOptions
                                            options={productOptions}
                                            handleOptionSelection={handleOptionSelection}
                                            requiredOption={isRequired}
                                            productOptionRef={productOptionRef}
                                        />
                                    )}
                                    {/* @*accoridon section*@ */}
                                    <div className="prod-detail-content-wrap">
                                        {productDetails && productDetails.description && (
                                            <AccordionItem title="Description">
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: productDetails.description,
                                                    }}
                                                ></p>
                                            </AccordionItem>
                                        )}
                                        {productDetails && productDetails.specifications && (
                                            <AccordionItem title="Size and Specifications">
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: productDetails.specifications,
                                                    }}
                                                ></p>
                                            </AccordionItem>
                                        )}
                                        {product.documents && product.documents.length > 0 && (
                                            <AccordionItem title="Manufacturer Guides">
                                                {product.documents.map((docs) => {
                                                        return (
                                                            <a
                                                                key={docs.id}
                                                            href={'/api/media/pdf/' + product.id + '/' + encodeURI(docs.fileName)}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <p> {docs.title}</p>
                                                            </a>
                                                        );
                                                })}
                                            </AccordionItem>
                                        )}
                                    </div>
                                    {/* @*Shipping area*@ */}
                                    <div className="prod-detail-content-wrap pro-phipping-wrap">
                                        <p className="prod-shipping-est-title">
                                            {productLine &&
                                                productLine.manufacturer &&
                                                productLine.manufacturer.shippingNote}
                                        </p>
                                        <div className="product-detail-price-button-wrap">
                                            <p className="product-detail-price">
                                                <span className="product-detail-price-amount">
                                                    {formatUSD(updatedPrice)}
                                                </span>{' '}
                                                (District Contract Price)
                                            </p>
                                            {!isPreview ?
                                                <div className="prod-shipping-wrap">
                                                    <Quantity setQuantity={handleQuantityChange} />
                                                    {!isAdding &&
                                                        <div className="add-my-product-wrapper">
                                                            <div
                                                                className="prod-detail-cta"
                                                                onClick={handleAddToMyProject}
                                                            >
                                                                {localStorage.typicallayout === 'true' ? 'Add Typical Product' : 'Add to My Project'}
                                                            </div>
                                                            {(!getImpersonationCheck() && localStorage.typicallayout != 'true') &&


                                                                <div className="product-power-wrap ">

                                                                    {!wishlistButtonDisabled ? (
                                                                        <div
                                                                            className="prod-detail-cta wishlist-btn"
                                                                            onClick={handleAddToWishList}
                                                                        >
                                                                            {' '}
                                                                            Add to Wishlist
                                                                        </div>
                                                                    ) : (
                                                                        <div className="prod-detail-cta wishlist-btn disabled">
                                                                            {' '}
                                                                            Added
                                                                        </div>
                                                                    )}

                                                                </div>}
                                                        </div>
                                                    }
                                                </div> : ''
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* @*Bought Together Slider goes here*@ */}
                {localStorage.typicallayout != 'true' &&
                    <div className="bought-together-outer product-details-wrap">
                        <div className="default-center bought-together-default ">
                            <p className="bought-together-title">Bought Together</p>

                            {<ProductSlider productId={productId} />}
                        </div>
                    </div>
                }
                {/* <!-- End User this HTML for Slider --> */}
            </div>
        </>
    );
}
