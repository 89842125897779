import TagManager from 'react-gtm-module';
import { getLoginUserDetails } from '../api/adminuserapi';
export const sendGTMEvent = (eventData = {}) => {
	if (eventData.ecommerce) {
		TagManager.dataLayer({
			dataLayer: {
				ecommerce: null,
			},
		});
	}

	// Send the actual event
	TagManager.dataLayer({
		dataLayer: {
			...eventData,
		},
	});
};

export const gtmRequestDemo = (info) => {
	return { event: 'form_submit', form_name: 'Contact Us', form_data: info };
};

export const gtmAddToCart = (
	productDetails,
	productOptions,
	selectedOptionIds,
	quantity
) => {
	const eventObj = returnEcommerceObject(
		productDetails,
		'add_to_cart',
		quantity
	);

	const selectedOptions = selectedOptionIds.map((selectedId) => {
		const optionGroup = productOptions.find((group) =>
			group.items.some((item) => item.id === selectedId)
		);
		const selectedItem = optionGroup.items.find(
			(item) => item.id === selectedId
		);

		return {
			option_group: optionGroup.title,
			option_value: selectedItem.title,
			color_name: selectedItem.color?.name || null,
			color_hex: selectedItem.color?.hex || null,
		};
	});

	if (selectedOptions.length > 0) {
		eventObj.ecommerce.items[0].selected_options = selectedOptions;

	}

	sendGTMEvent(eventObj);
};

export const gtmLogin = () => {
	getLoginUserDetails().then((response) => {
    const userData = response.data.data;

		const eventObj = {
			event: 'user_login',
      email: userData.email,
      fullName: userData.fullName,
      isDistrictManager: userData.isDistrictManager,
      isApprover: userData.isApprover,
      user_roles: userData.userRoles.map(role => role.siteRole.name),

		};
		sendGTMEvent(eventObj);
	});
};

export const gtmViewProduct = (info) => {
	const eventObj = returnEcommerceObject(info, 'view_item');

	sendGTMEvent(eventObj);
};

export const gtmAddToWishList = (
	productDetails,
	productOptions,
	selectedOptionIds,
	quantity
) => {
	const eventObj = returnEcommerceObject(
		productDetails,
		'add_to_wishlist',
		quantity
	);

	const selectedOptions = selectedOptionIds.map((selectedId) => {
		const optionGroup = productOptions.find((group) =>
			group.items.some((item) => item.id === selectedId)
		);
		const selectedItem = optionGroup.items.find(
			(item) => item.id === selectedId
		);

		return {
			option_group: optionGroup.title,
			option_value: selectedItem.title,
			color_name: selectedItem.color?.name || null,
			color_hex: selectedItem.color?.hex || null,
		};
	});
	if (selectedOptions.length > 0) {
		eventObj.ecommerce.items[0].selected_options = selectedOptions;
	}

	sendGTMEvent(eventObj);
};

function returnEcommerceObject(info, eventName, quantity = 1) {
	const event = {
		event: eventName,
		ecommerce: {
			currency: 'USD',
			value: info.details?.price * quantity,
			items: [
				{
					item_id: info.details?.productId,
					item_name: info.details?.name,
					price: info.details?.price,
					item_brand: info.productLine?.manufacturer?.name,
					productline_name: info.productLine?.name,
					quantity: quantity,
				},
			],
		},
	};
	return event;
}

//Was working on the cta_click events
//export const gtmCTALink = () => {
//    const handleCTAClick = () => {
//        gtmEvent({
//            event: 'cta_click',
//            cta_text: 'View Products',
//            cta_id: cta - 1,
//        });
//    };
//}
