import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './home.css';

import ProductsTypesHero from "../../assets/landing/products-types-hero.jpg";
import RoomTypesHero from "../../assets/landing/room-types-hero.jpg";
import NavImagePlaceholder from "../../assets/nav-image-placeholder.jpg";
import { useNavigate } from 'react-router-dom';
import { checkForDistrictId } from '../../helpers/checkForDistrictId';
import Banner from '../../components/common/ShowBannerDetails';
import { Scrollbars } from "react-custom-scrollbars-2";
import { updateTitle, updateMetaDescription } from '../../helpers/titleHelper';
/*import { sendGTMEvent, gtmCTALink } from '../../helpers/gtmHelper';*/

export default function GetHone() {
    updateTitle('Your Partner in Facility Management Excellence');
    updateMetaDescription('Welcome to Facility Standard! Discover top-tier facility management solutions designed to optimize your operations and enhance safety. Explore our services today!');
    const navigate = useNavigate();
    const typicalLayoutTypes = useSelector((state) => state.categories.typicalLayoutType);

    

    useEffect(() => {
        checkForDistrictId(navigate); //redirect to admin/districts if user is not logged into district
        //sendGTMEvent('gtmCTAClick', { buttonName: 'View Products' });
        //sendGTMEvent('gtmCTAClick', { buttonName: 'View Typical Layouts' });
    }, []);

    
    return (

        <>
            <Banner/>

            <div>
                <section className="types" data-color-section="dark">
                    <div className="hero-slider-wrap wrapper-full">
                        <div className="types-inner">
                            <div className="types-bg" data-bg-wrap="">
                                <div className="types-img bg-b-lazy">
                                    <img src={ProductsTypesHero} className="img-banner video-banner" alt="product hero" />
                                </div>
                                <div className="types-img bg-b-lazy">
                                    <img src={RoomTypesHero} className="img-banner video-banner" alt="room hero" />
                                </div>

                            </div>
                            <div className="types-list" data-types-list="">
                                <Link to="/products" className="card types-card wow fadeInUp" data-types-card="" data-wow-delay="0s">
                                    <div className="card-bg bg-b-lazy b-lazy b-loaded lazyloaded" data-bg-size="cover" data-bg-pos="center" data-back="../assets/landing/products-types-hero.jpg">
                                        <div className="card-mask"></div>
                                    </div>
                                    <div className="card-content">
                                        <h1 className="hero-subtitle card-h2">
                                            Products
                                        </h1>
                                        <div className="card-content-inner">
                                            <div className="hero-tile-desc">
                                                <p>Browse all our products and filter your results by room type, categories and grade level.</p>
                                            </div>
                                            <span className="hero-button-wrap">
                                                <span className="hero-tile-link" data-cta="ViewProducts">
                                                    View Products
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </Link>
                                {/*<div className="card types-card wow fadeInUp" data-types-card="" data-wow-delay="0s" onClick={showSubmenu}>
                                    <div className="card-bg bg-b-lazy b-lazy b-loaded lazyloaded" data-bg-size="cover" data-bg-pos="center" data-back="../assets/landing/room-types-hero.jpg">
                                        <div className="card-mask"></div>
                                    </div>
                                    <div className="card-content">
                                        <span className="hero-subtitle card-h2">
                                            Room Types
                                        </span>
                                        <div className="card-content-inner">
                                            <div className="hero-tile-desc">
                                                <p>Look for specific products based on the room type you&apos;re trying to furnish. Elementary, science, art, cafeteria, computer furniture and more.</p>
                                            </div>
                                            <span className="hero-button-wrap">
                                                <div className="hero-tile-link" id="browseRoom">
                                                    View Room Types
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>*/}

                                {typicalLayoutTypes && typicalLayoutTypes.length > 0 &&
                                    <Link to="/typical-layouts" className="card types-card wow fadeInUp" data-types-card="" data-wow-delay="0s">
                                        <div className="card-bg bg-b-lazy b-lazy b-loaded lazyloaded" data-bg-size="cover" data-bg-pos="center" data-back="../assets/landing/typical-layouts-hero.jpg">
                                            <div className="card-mask"></div>
                                        </div>
                                        <div className="card-content">
                                            <span className="hero-subtitle card-h2">
                                                Typical Layouts
                                            </span>
                                            <div className="card-content-inner">
                                                <div className="hero-tile-desc">
                                                    <p>Look for specific products based on the room type you&apos;re trying to furnish. Elementary, science, art, cafeteria, computer furniture and more.</p>
                                                </div>
                                                <span className="hero-button-wrap">
                                                    <div className="hero-tile-link">
                                                        View Typical Layouts
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                }

                                

                            </div>
                        </div>
                    </div>
                </section>
                <div className="nav-link-dropdown" id="browselayoutTypes">
                    <div className="nav-overlay typical-layout">
                        <div className="nav-overlay-inner">
                            <div className="nav-overlay-content-inner scrollbar-outer">
                                <a href="javascript:;" className="nav-overlay-close">
                                    <span className="nav-overlay-close-icon">
                                        <span className="material-symbols-rounded">close</span>
                                    </span>
                                </a>
                                <Scrollbars
                                    autoHeight
                                    autoHeightMin={400}
                                    autoHeightMax={800}
                                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" />}
                                    renderTrackVertical={props => <div {...props} className="track-vertical" />}
                                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" />}
                                    renderThumbVertical={props => <div {...props} className="thumb-vertical" />}
                                    renderView={props => <div {...props} className="view" />}>
                                    <div className="nav-overlay-center">
                                        <div className="nav-drop-layout-wrap">
                                            <a href="javascript:;" className="type-back-link">
                                                <span className="material-symbols-rounded">arrow_back_ios</span>
                                                Back
                                            </a>
                                            <p className="nav-drop-title">Browse by Typical Layout</p>
                                            <div className="nav-block-wrap">
                                                <a href="/broken" className="nav-block-item">
                                                    <span className="nav-block-img-wrap">
                                                        <img src={NavImagePlaceholder} alt="placeholder" className="nav-block-cover-thumb" />
                                                    </span>
                                                    <span className="nav-block-content">
                                                        <span className="nav-block-title">Infant &amp; Toddler</span>
                                                    </span>
                                                </a>
                                                <a href="/broken" className="nav-block-item">
                                                    <span className="nav-block-img-wrap">
                                                        <img src={NavImagePlaceholder} alt="placeholder" className="nav-block-cover-thumb" />
                                                    </span>
                                                    <span className="nav-block-content">
                                                        <span className="nav-block-title">Preschool</span>
                                                    </span>
                                                </a>
                                                <a href="/broken" className="nav-block-item">
                                                    <span className="nav-block-img-wrap">
                                                        <img src={NavImagePlaceholder} alt="placeholder" className="nav-block-cover-thumb" />
                                                    </span>
                                                    <span className="nav-block-content">
                                                        <span className="nav-block-title">Elementary</span>
                                                    </span>
                                                </a>
                                                <a href="/broken" className="nav-block-item">
                                                    <span className="nav-block-img-wrap">
                                                        <img src={NavImagePlaceholder} alt="placeholder" className="nav-block-cover-thumb" />
                                                    </span>
                                                    <span className="nav-block-content">
                                                        <span className="nav-block-title">Middle/High School</span>
                                                    </span>
                                                </a>
                                                <a href="/broken" className="nav-block-item">
                                                    <span className="nav-block-img-wrap">
                                                        <img src={NavImagePlaceholder} alt="placeholder" className="nav-block-cover-thumb" />
                                                    </span>
                                                    <span className="nav-block-content">
                                                        <span className="nav-block-title">Art Room &amp; Drafting</span>
                                                    </span>
                                                </a>
                                                <a href="/broken" className="nav-block-item">
                                                    <span className="nav-block-img-wrap">
                                                        <img src={NavImagePlaceholder} alt="placeholder" className="nav-block-cover-thumb" />
                                                    </span>
                                                    <span className="nav-block-content">
                                                        <span className="nav-block-title">Auditorium &amp; Lecture Hall</span>
                                                    </span>
                                                </a>
                                                <a href="/broken" className="nav-block-item">
                                                    <span className="nav-block-img-wrap">
                                                        <img src={NavImagePlaceholder} alt="placeholder" className="nav-block-cover-thumb" />
                                                    </span>
                                                    <span className="nav-block-content">
                                                        <span className="nav-block-title">Cafeteria &amp; Food Court</span>
                                                    </span>
                                                </a>
                                                <a href="/broken" className="nav-block-item">
                                                    <span className="nav-block-img-wrap">
                                                        <img src={NavImagePlaceholder} alt="placeholder" className="nav-block-cover-thumb" />
                                                    </span>
                                                    <span className="nav-block-content">
                                                        <span className="nav-block-title">Computer Lab &amp; Training</span>
                                                    </span>
                                                </a>
                                                <a href="/broken" className="nav-block-item">
                                                    <span className="nav-block-img-wrap">
                                                        <img src={NavImagePlaceholder} alt="placeholder" className="nav-block-cover-thumb" />
                                                    </span>
                                                    <span className="nav-block-content">
                                                        <span className="nav-block-title">Hallway &amp; Common Area</span>
                                                    </span>
                                                </a>
                                                <a href="/broken" className="nav-block-item">
                                                    <span className="nav-block-img-wrap">
                                                        <img src={NavImagePlaceholder} alt="placeholder" className="nav-block-cover-thumb" />
                                                    </span>
                                                    <span className="nav-block-content">
                                                        <span className="nav-block-title">Library &amp; Media Center</span>
                                                    </span>
                                                </a>
                                                <a href="/broken" className="nav-block-item">
                                                    <span className="nav-block-img-wrap">
                                                        <img src={NavImagePlaceholder} alt="placeholder" className="nav-block-cover-thumb" />
                                                    </span>
                                                    <span className="nav-block-content">
                                                        <span className="nav-block-title">Makerspace &amp; STEM</span>
                                                    </span>
                                                </a>
                                                <a href="/broken" className="nav-block-item">
                                                    <span className="nav-block-img-wrap">
                                                        <img src={NavImagePlaceholder} alt="placeholder" className="nav-block-cover-thumb" />
                                                    </span>
                                                    <span className="nav-block-content">
                                                        <span className="nav-block-title">Music &amp; Choir</span>
                                                    </span>
                                                </a>
                                                <a href="/broken" className="nav-block-item">
                                                    <span className="nav-block-img-wrap">
                                                        <img src={NavImagePlaceholder} alt="placeholder" className="nav-block-cover-thumb" />
                                                    </span>
                                                    <span className="nav-block-content">
                                                        <span className="nav-block-title">Nurse&apos;s Office</span>
                                                    </span>
                                                </a>
                                                <a href="/broken" className="nav-block-item">
                                                    <span className="nav-block-img-wrap">
                                                        <img src={NavImagePlaceholder} alt="placeholder" className="nav-block-cover-thumb" />
                                                    </span>
                                                    <span className="nav-block-content">
                                                        <span className="nav-block-title">Outdoor Classroom</span>
                                                    </span>
                                                </a>
                                                <a href="/broken" className="nav-block-item">
                                                    <span className="nav-block-img-wrap">
                                                        <img src={NavImagePlaceholder} alt="placeholder" className="nav-block-cover-thumb" />
                                                    </span>
                                                    <span className="nav-block-content">
                                                        <span className="nav-block-title">Playground &amp; Parks</span>
                                                    </span>
                                                </a>
                                                <a href="/broken" className="nav-block-item">
                                                    <span className="nav-block-img-wrap">
                                                        <img src={NavImagePlaceholder} alt="placeholder" className="nav-block-cover-thumb" />
                                                    </span>
                                                    <span className="nav-block-content">
                                                        <span className="nav-block-title">Science Room &amp; Labs</span>
                                                    </span>
                                                </a>
                                                <a href="/broken" className="nav-block-item">
                                                    <span className="nav-block-img-wrap">
                                                        <img src={NavImagePlaceholder} alt="placeholder" className="nav-block-cover-thumb" />
                                                    </span>
                                                    <span className="nav-block-content">
                                                        <span className="nav-block-title">Teacher&apos;s Lounge &amp; Breakroom</span>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </Scrollbars>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

/*
function showSubmenu(e) {
    e.preventDefault();
    let htmlElement = document.querySelector('html');
    htmlElement.classList.remove('typical-product-nav-open');

    if (htmlElement.classList.contains('product-nav-open'))
        htmlElement.classList.remove('product-nav-open');
    else htmlElement.classList.add('product-nav-open');

    // navigate('/new-url-path');
}
*/